@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'PT Sans', sans-serif;
}

#root {
  height: 100vh;
  height: 100svh;
}

.home,
.opportunities,
.catelog,
.pricebook {
  display: flex;
  height: 90vh;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
}

.home {
  align-items: flex-start;
  /* background-image: url('./images/cyber3.jpg'); */
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

  color: #000;
  font-size: 100px;
}

.loader {
  display: flex;
  width: 100%;
  padding: 0.5rem 0.8rem 0.5rem 0.8rem;
  margin: 0.9vw 0;
  border: 0;
  border-radius: 5px;
  font-size: 16px;
  align-items: center;
  justify-content: center;
}

.catelog {
  /* background-image: url('./images/img-1.jpg'); */
  display: block;
  align-items: center;
  background-position: center;
  background-size: fill;
  background-repeat: no-repeat;
  color: #000;
  font-size: 30px;
}

.pricebook {
  /* background-image: url('./images/img-8.jpg'); */
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #000;
  font-size: 30px;
}

.task {
  display: flexbox;
  color: #000;
  /* background: #f4f4f4; */
  background-color: rgba(93, 159, 67, 0.5);
  margin: 5px auto;
  text-align: left;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 10px;
  justify-self: center;
  overflow: auto;
  max-width: 500px;
}
.task_pb {
  display: flexbox;
  color: #000;
  /* background: #f4f4f4; */
  background-color: rgba(93, 159, 67, 0.5);
  margin: 5px auto;
  text-align: left;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 10px;
  justify-self: center;
  overflow: auto;
  max-width: 800px;
  height: auto;
}

.task_pb input {
  display: block;
  width: 100%;
  padding: 0.5rem 0.8rem 0.5rem 0.8rem;
  margin: 0.9vw 0;
  border: 0;
  border-radius: 5px;
  font-size: 16px;
}
.search-container {
  position: sticky;
  top: 0;
  padding: 5px 0;
  z-index: 1;
  width: 100%;
  background-color: white;
}
.search {
  display: flexbox;
  color: #000;
  /* background: #f4f4f4; */
  background-color: rgb(176 207 163);
  margin: auto;
  text-align: left;
  padding: 10px 20px;
  border-radius: 10px;
  justify-self: center;
  overflow: auto;
  max-width: 500px;
  box-shadow:
    0 1px 3px 0 rgb(0 0 0 / 0.1),
    0 1px 2px -1px rgb(0 0 0 / 0.1);
}
.search input {
  display: flexbox;
  width: 100%;
  justify-self: center;
  padding: 0.5rem 0.8rem 0.5rem 0.8rem;
  margin: 0.9vw 0;
  border: 0;
  border-radius: 5px;
  font-size: 16px;
}
.task input {
  display: block;
  width: 100%;
  padding: 0.5rem 0.8rem 0.5rem 0.8rem;
  margin: 0.9vw 0;
  border: 0;
  border-radius: 5px;
  font-size: 16px;
}

.task h3 {
  text-align: center;
}

.catelog h1 {
  text-align: center;
}

.noDisp {
  display: none;
}
.btn {
  display: grid;
  text-align: center;
  background: #000;
  color: #fff;
  border: none;
  width: 200px;
  padding: 5px 10px;
  margin: 5px auto;
  border-radius: 5px;
  cursor: pointer;
  text-decoration: none;
  font-size: 15px;
  font-family: 'Poppins', sans-serif;
}
.btn-load {
  display: grid;
  text-align: center;
  background: #000;
  color: #fff;
  border: none;
  width: 200px;
  padding: 5px 10px;
  margin: 5px auto;
  margin-top: 15px;
  border-radius: 5px;
  cursor: pointer;
  text-decoration: none;
  font-size: 15px;
  font-family: 'Poppins', sans-serif;
}

.task-block {
  justify-self: center;
}

h3 {
  text-align: center;
  font-size: 24px;
}

h2 {
  text-align: center;
  font-size: 30px;
}

img {
  max-height: 60px;
}

a {
  text-decoration: none;
}

.centered {
  text-align: center;
}

.red {
  color: red;
}

.projectNumberLabel {
  text-align: center;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 1rem;
}

.overlay > span > :nth-child(1) {
  z-index: 1;
  border-color: transparent transparent rgb(0 180 0) rgb(0 180 0) !important;
}

.overlay > span > :nth-child(2) {
  z-index: 1;
  border-color: rgb(0 180 0) transparent transparent rgb(0 180 0) !important;
}

.overlay > span > span:nth-last-child(-n + 4) {
  top: 64px !important;
  background-color: white !important;
}

/* For Webkit browsers like Chrome and Safari */
::-webkit-scrollbar {
  width: 10px; /* Width of the scrollbar track */
}

::-webkit-scrollbar-thumb {
  background-color: #888; /* Color of the scrollbar thumb */
  border-radius: 5px; /* Rounded corners for the thumb */
}
